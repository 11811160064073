import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Footer from "./components/Footer";
import TopBar from "./components/TopBar";
import MapScreen from "./components/Screens/MapScreen";
import EventScreen from "./components/Screens/EventScreen";
import PartyScreen from "./components/Screens/PartyScreen";

const MainPage = () => {
  const textRefs = useRef([]);
  const imageRefs = useRef([]);

  return (
    <Background>
      <MainContainer>
        <TopBar />
        <ScreenContainer>
          <EventScreen />
        </ScreenContainer>
        <ScreenContainer>
          <PartyScreen />
        </ScreenContainer>
        <ScreenContainer>
          <MapScreen textRefs={textRefs} imageRefs={imageRefs} />
        </ScreenContainer>
      </MainContainer>
      <FullWidthFooter>
        <Footer />
      </FullWidthFooter>
    </Background>
  );
};

export default MainPage;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1a1a1a;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1a1a1a;
  width: 100%;
  flex: 1;
`;

const ScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
`;

const Screen = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 80vh;
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const FullWidthFooter = styled.div`
  width: 100%;
  background-color: #262626;
`;
