import React from "react";
import styled from "styled-components";

const StoreButton = ({ text }) => {
  return <AnimatedButton>{text}</AnimatedButton>;
};

export default StoreButton;

const AnimatedButton = styled.button`
  margin-top: 20px;
  margin-right: 20px;
  background-color: transparent;
  border: 1px solid #daff43;
  border-radius: 20px;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  z-index: 2;
  transition: background-color 0.2s ease-in;
  &:hover {
    color: #111;
    background-color: #daff43;
    transition: background-color 0.2s ease-out;
  }
`;
