// src/components/Screens/MapScreen.jsx

import React, { useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Wave from "../Wave";

gsap.registerPlugin(ScrollTrigger);

const MapScreen = ({ textRefs, imageRefs }) => {
  useEffect(() => {
    textRefs.current.forEach((textRef) => {
      gsap.fromTo(
        textRef,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 2,
          scrollTrigger: {
            trigger: textRef,
            start: "top 70%",
            end: "top 60%",
            scrub: true,
          },
        }
      );
    });

    imageRefs.current.forEach((imageRef) => {
      gsap.fromTo(
        imageRef,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 2,
          scrollTrigger: {
            trigger: imageRef,
            start: "top 80%",
            end: "top 60%",
            scrub: true,
            onComplete: () => {
              gsap.to(imageRef, {
                y: 20,
                duration: 2,
                repeat: -1,
                yoyo: true,
                ease: "power1.inOut",
              });
            },
          },
        }
      );
    });
  }, []);

  return (
    <Screen imageUrl="/assets/background.png">
      <MapDescSection>
        <Description
          fontSize="20px"
          querySize="28px"
          ref={(el) => (textRefs.current[0] = el)}
        >
          인플루언서와 함께하는 트렌디한 파티
        </Description>
        <Description
          fontSize="28px"
          querySize="20px"
          ref={(el) => (textRefs.current[1] = el)}
        >
          파티를 위한 완벽한 길잡이
        </Description>
      </MapDescSection>

      <ColWrapper>
        <Img ref={(el) => (imageRefs.current[0] = el)} />
        <Wave />
      </ColWrapper>
      <ItemSection>
        <ItemImage
          src="/assets/mapitem1.png"
          ref={(el) => (imageRefs.current[1] = el)}
        />
        <ItemImage
          src="/assets/mapitem2.png"
          ref={(el) => (imageRefs.current[2] = el)}
        />
      </ItemSection>
    </Screen>
  );
};

export default MapScreen;

const Screen = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 1024px;
  width: 100%;
  height: 80vh;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Img = styled.img`
  width: 280px;
  z-index: 2;
  content: url(/assets/MapMockup.png);
  animation: float 2s ease-in-out infinite;

  @media (max-width: 800px) {
    width: 240px;
    content: url(/assets/map.png);
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const MapDescSection = styled.section`
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 2;
  }
`;

const Description = styled.p`
  margin-bottom: 8px;
  color: #fff;
  font-size: ${(props) => props.fontSize || "16px"};
  @media (max-width: 800px) {
    font-size: ${(props) => props.querySize || "16px"};
  }
`;

const ItemSection = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    display: none;
  }
`;

const ColWrapper = styled.div`
  position: relative;
  display: flex;
`;

const ItemImage = styled.img`
  margin: 10px;
  width: 220px;
  z-index: 2;
`;
