import React from "react";
import styled from "styled-components";

const TopBar = () => {
  return (
    <TopBarWrapper>
      <TopBarContainer>
        <TopBarContent>
          <Logo src="/assets/dayventlogo.png" alt="Dayvent Logo" />
        </TopBarContent>
      </TopBarContainer>
    </TopBarWrapper>
  );
};

export default TopBar;

const TopBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
`;

const TopBarContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: rgba(26, 26, 26, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const TopBarContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  max-width: 1024px;
  padding: 0 20px;
  box-sizing: border-box;
`;

const Logo = styled.img``;
