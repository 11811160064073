import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import styled from "styled-components";

const Wave = ({ top, right }) => {
  const waveRefs = useRef([]);
  const markerRefs = useRef([]);

  useEffect(() => {
    waveRefs.current.forEach((waveElement, index) => {
      gsap.fromTo(
        waveElement,
        {
          scale: 0.2,
          opacity: 1,
        },
        {
          scale: 3.5,
          opacity: 0,
          duration: 4,
          repeat: -1,
          ease: "power2.out",
          delay: index * 0.3,
        }
      );
    });

    markerRefs.current.forEach((markerElement, index) => {
      gsap.fromTo(
        markerElement,
        {
          scale: 0,
          opacity: 0,
        },
        {
          scale: 1,
          opacity: 0.8,
          duration: 2,
          ease: "power2.out",
          delay: index * 0.3,
          onComplete: () => {
            gsap.to(markerElement, {
              opacity: 0,
              duration: 2,
              repeat: -1,
              yoyo: true,
              ease: "power2.out",
              delay: index * 0.3,
            });
          },
        }
      );
    });
  }, []);

  const randomPosition = () => {
    const maxRange = 150 * 3;
    const offsetX = (Math.random() - 0.5) * maxRange;
    const offsetY = (Math.random() - 0.5) * maxRange;
    return { offsetX, offsetY };
  };

  return (
    <WaveContainer>
      {Array.from({ length: 3 }).map((_, index) => (
        <WaveElement
          key={index}
          ref={(el) => (waveRefs.current[index] = el)}
          top={top}
          right={right}
        />
      ))}
      {Array.from({ length: 15 }).map((_, index) => {
        const { offsetX, offsetY } = randomPosition();
        return (
          <Marker
            key={index}
            ref={(el) => (markerRefs.current[index] = el)}
            style={{ transform: `translate(${offsetX}px, ${offsetY}px)` }}
            top={top}
            right={right}
          />
        );
      })}
    </WaveContainer>
  );
};

const WaveContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const WaveElement = styled.div`
  position: absolute;
  right: ${(props) => props.right || "60px"};
  top: ${(props) => props.top || "150px"};
  width: 150px;
  height: 150px;
  border: 1px solid #6f841d;
  border-radius: 50%;
  background-color: transparent;
  @media (max-width: 800px) {
    top: 150px;
    right: 40px;
  }
`;

const Marker = styled.div`
  position: absolute;
  right: ${(props) => props.right || "80px"};
  top: ${(props) => props.top || "150px"};
  width: 8px;
  height: 8px;
  background-color: #daff43;
  border-radius: 50%;
  border: 3px solid #6f841d;
  opacity: 0;
  @media (max-width: 800px) {
    right: 40px;
  }
`;

export default Wave;
