// Coupon.js
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";

const Coupon = () => {
  const imgRef = useRef(null);

  useEffect(() => {
    if (imgRef.current) {
      gsap.to(imgRef.current, {
        y: "10",
        repeat: -1,
        yoyo: true,
        ease: "power1.inOut",
        duration: 1.2,
      });
    }
  }, []);

  return (
    <CouponContainer>
      <FloatingImage
        src="/assets/CouponMockup.png"
        ref={imgRef}
        alt="Floating"
      />
    </CouponContainer>
  );
};

export default Coupon;

const CouponContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  overflow: hidden;
`;

const FloatingImage = styled.img`
  width: auto;
  height: 80%;
`;
