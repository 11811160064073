import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterHeader>
          <FooterLogo src="/assets/dayventlogo.png" />
        </FooterHeader>
        <FooterBody>
          <Wrapper>
            <FooterDescription>상호명 : 주식회사 닷투라인</FooterDescription>
            <FooterDescription>대표이사 : 이태희</FooterDescription>
            <FooterDescription>개인정보책임관리자 : 이태희</FooterDescription>
          </Wrapper>
          <FooterDescription>
            주소 : 서울 송파구 올림픽로 111 (잠실동, 잠실엘스) 4층
          </FooterDescription>
          <FooterDescription>사업자등록번호 : 450-88-03160</FooterDescription>
        </FooterBody>
        <FooterBottom>
          <FooterDescription>이용약관</FooterDescription>
          <FooterDescription>개인정보처리방침</FooterDescription>
          <FooterDescription>위치기반서비스 이용약관</FooterDescription>
          <FooterDescription>청소년보호정책</FooterDescription>
        </FooterBottom>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #1a1a1a;
  padding: 20px 0;
`;

const FooterContent = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const FooterHeader = styled.section`
  display: flex;
  align-items: center;
  height: 16px;
  margin: 20px 0;
`;

const FooterLogo = styled.img`
  height: 100%;
`;

const FooterBody = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0;
`;

const FooterBottom = styled.section`
  display: flex;
  padding: 20px 0;
  border-top: 2px solid #555;
`;

const FooterDescription = styled.p`
  font-size: 12px;
  color: #aaa;
  margin-right: 20px;
`;

const Wrapper = styled.section`
  display: flex;
`;
