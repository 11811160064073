import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import ContentBox from "../atoms/ContentBox";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FaRegHeart } from "react-icons/fa";
import { BiParty } from "react-icons/bi";
import { RiCoupon2Line } from "react-icons/ri";
import Logo from "../molecules/Logo";
import Coupon from "../molecules/Coupon";
import Casting from "../molecules/Casting";
import Place from "../molecules/Place";
import Party from "../molecules/Party";

gsap.registerPlugin(ScrollTrigger);

const PartyScreen = () => {
  const boxRefs = useRef([]);
  const contentRefs = useRef([]);
  const [showDefault, setShowDefault] = useState(true);

  useEffect(() => {
    boxRefs.current.forEach((boxRef) => {
      gsap.fromTo(
        boxRef,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 2,
          scrollTrigger: {
            trigger: boxRef,
            start: "top 90%",
            end: "top 70%",
            scrub: true,
          },
        }
      );
    });
  }, []);

  const handleMouseEnter = (index) => {
    setShowDefault(false);
    gsap.to(contentRefs.current[0], {
      opacity: 0,
      duration: 0.05,
    });
    gsap.to(contentRefs.current[index], {
      opacity: 1,
      duration: 0.05,
    });
  };

  const handleMouseLeave = (index) => {
    gsap.to(contentRefs.current[index], {
      opacity: 0,
      duration: 0,
    });
    gsap.to(contentRefs.current[0], {
      opacity: 1,
      duration: 0,
    });
  };

  console.log(showDefault);

  return (
    <Screen>
      <Container>
        <ContentSection>
          {showDefault && (
            <Content opacity={1}>
              <Logo />
            </Content>
          )}
          <Content ref={(el) => (contentRefs.current[0] = el)}>
            <Logo />
          </Content>
          <Content ref={(el) => (contentRefs.current[1] = el)}>
            <Party />
          </Content>
          <Content ref={(el) => (contentRefs.current[2] = el)}>
            <Place />
          </Content>
          <Content ref={(el) => (contentRefs.current[3] = el)}>
            <Coupon />
          </Content>
          <Content ref={(el) => (contentRefs.current[4] = el)}>
            <Casting />
          </Content>
        </ContentSection>
        <BoxSection>
          <Box
            ref={(el) => (boxRefs.current[0] = el)}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={() => handleMouseLeave(1)}
          >
            <ContentBox
              icon={BiParty}
              title="Party List"
              text="인플루언서가 참여하는 "
              subtext="다양한 파티 리스트"
            />
          </Box>
          <Box
            ref={(el) => (boxRefs.current[1] = el)}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={() => handleMouseLeave(2)}
          >
            <ContentBox
              icon={FaRegHeart}
              title="Place"
              text="인플루언서와 함께하는"
              subtext="독특한 파티 플레이스"
            />
          </Box>
          <Box
            ref={(el) => (boxRefs.current[2] = el)}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={() => handleMouseLeave(3)}
          >
            <ContentBox
              icon={RiCoupon2Line}
              title="Time Coupon"
              text="파티가 개최되는 인근 플레이스의"
              subtext="타임쿠폰을 파티원들에게 실시간 제공!"
            />
          </Box>
          <Box
            ref={(el) => (boxRefs.current[3] = el)}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={() => handleMouseLeave(4)}
          >
            <ContentBox
              icon={BiParty}
              title="Casting"
              text="인플루언서가 참여하는"
              subtext="성공적인 캐스팅"
            />
          </Box>
        </BoxSection>
      </Container>
    </Screen>
  );
};

export default PartyScreen;

const Screen = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to top, #e14fad 0%, #f9d423 100%);
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1024px;
  width: 100%;
`;

const ContentSection = styled.section`
  position: relative;
  width: 60%;
  height: 400px;
  margin: auto;
`;

const BoxSection = styled.section`
  display: flex;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
  width: 840px;
  @media (max-width: 800px) {
    width: 80%;
  }
`;

const Box = styled.div`
  margin: 15px;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => props.opacity || 0};
  transition: opacity 0.3s ease-in-out;
`;
