import { React, useEffect, useState } from "react";
import styled from "styled-components";

const ContentBox = ({ icon: Icon, title, text, subtext }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container>
      <ContentHeader>
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <ContentTitle>{title}</ContentTitle>
      </ContentHeader>
      {!isMobile && (
        <ContentBody>
          <ContentText>{text}</ContentText>
          <ContentText>{subtext}</ContentText>
        </ContentBody>
      )}
    </Container>
  );
};

export default ContentBox;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  width: 260px;
  height: 200px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 800px) {
    width: 200px;
  }
  @media (max-width: 600px) {
    width: 160px;
  }
`;

const IconWrapper = styled.div`
  font-size: 3rem;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const ContentTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #412cab;
`;

const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 20px 10px;
`;

const ContentText = styled.p`
  font-size: 14px;
  color: #222;
  word-break: keep-all;
  margin-top: 12px;
`;
