import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import Wave from "../Wave";
import StoreButton from "../atoms/StoreButton";

const EventScreen = () => {
  const textRefs = useRef([]);
  const imgRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      textRefs.current,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.5, duration: 1 }
    );

    gsap.to(imgRef.current, {
      y: "+=5",
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      duration: 1.2,
    });
  }, []);

  return (
    <Screen imageUrl="/assets/background.png">
      <ColWrapper>
        <MapDescSection>
          <Description
            ref={(el) => (textRefs.current[0] = el)}
            fontSize="28px"
            querySize="28px"
            fontWeight="500"
          >
            인플루언서들을 위한 파티 스케쥴러
          </Description>
          <Description
            ref={(el) => (textRefs.current[1] = el)}
            fontSize="16px"
            querySize="20px"
          >
            팔로워들과의 특별한 만남
          </Description>
          <Description
            ref={(el) => (textRefs.current[2] = el)}
            fontSize="16px"
            querySize="20px"
          >
            모두가 즐기는 파티 아이디어
          </Description>
        </MapDescSection>
        <Wrapper>
          <ButtonSection>
            <StoreButton text="GooglePlay" />
            <StoreButton text="AppStore" />
          </ButtonSection>
        </Wrapper>
      </ColWrapper>
      <Wrapper>
        <Img ref={imgRef} />
        <Wave right={`500px`} />
      </Wrapper>
    </Screen>
  );
};

export default EventScreen;

const Screen = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 1024px;
  width: 100%;
  height: 100vh;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Img = styled.img`
  width: 600px;
  z-index: 2;
  content: url(/assets/EventMockup.png);

  @media (max-width: 800px) {
    width: 240px;
    content: url(/assets/signinMockup.png);
  }
`;

const MapDescSection = styled.section`
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const Description = styled.p`
  margin-bottom: 8px;
  color: #fff;
  font-weight: ${(props) => props.fontWeight || "none"};
  font-size: ${(props) => props.fontSize || "16px"};
  word-break: keep-all;
  @media (max-width: 800px) {
    font-size: ${(props) => props.querySize || "16px"};
  }
`;

const ButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 50px;
  @media (max-width: 800px) {
    align-items: center;
    padding-left: 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  position: relative;
`;
